<template>
  <div class="container mb-5 mt-3">
    <div class="fund-name">
      <span>Fund Name: </span>
      <span class="ms-2">My Inventory</span>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="left-side-content bg-dark-100">
          <div class="table-wrapper table-responsive mt-4 pe-3">
            <div class="col-sm-3 text-center">
              <VideoBlock :link="getUrl()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import contractService from "../services";
import VideoBlock from "../components/VideoBlock.vue";
export default {
  name: "inventory",
  components: {
    VideoBlock,
  },
  data() {
    return {
      isShow: false,
      myNfts: 0,
      amount: 0,
    };
  },
  methods: {
    async redeem() {
      await contractService.createFund(
        3,
        "Bitcoin",
        60000,
        30,
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getUrl() {
      return "https://ipfs.io/ipfs/QmNiN3znaMwobtd3LqMERvmsmThnykwx9SncFgUJKcsQgo";
    },
  },
};
</script>

<style scoped>
.fund-name {
  width: 30%;
  margin: 0 auto 30px auto;
  padding: 12px;
  border: 2px solid;
  text-align: center;
  border-radius: 5px;
}
.table-wrapper {
  height: auto;
}
.left-side-content {
  padding: 4px 40px;
  border-radius: 8px;
}
.table > :not(caption) > * > * {
  background-color: inherit !important;
}
.bg-caribbean-green {
  background: #00dbaa;
}
.bg-red {
  background: #db0000;
}
.table-wrapper .table tr {
  border-bottom: 1px solid #979797;
}

.table th,
td {
  color: var(--whiteColor) !important;
  text-align: center;
  font-family: var(--BarlowFont);
}
.table th {
  font-size: 17px;
  font-weight: 600;
  padding: 20px 0;
}

.table td {
  padding: 20px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
}

.bottom-content__list {
  display: flex;
  align-items: self-start;
}
.right-side-content {
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 34px 38px 10px 26px;
}
.rounded {
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
}
.bg-pink {
  background: #ff7ca3;
}
.bg-blue-300 {
  background: #7cb0ff;
}
.bg-yellow-200 {
  background: #c5ff7c;
}
.right-side-content ul li {
  margin-bottom: 2rem;
}
</style>
