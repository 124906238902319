
<template>
  <div
    wire:loading
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center"
  >
    <div
      class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"
    ></div>
    <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
    <p class="w-13 text-center text-white">
      This may take a few seconds, please don't close this page.
    </p>
  </div>
</template>

<style scoped>
.loader {
  border-top-color: #3498db;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.w-full {
  width: 100%;
}

.h-screen {
  height: 100vh;
}

.z-50 {
  z-index: 50;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-gray-700 {
  background-color: #4a5568;
}

.opacity-75 {
  opacity: 0.75;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #ffffff;
}

.text-xl {
  font-size: 1.25rem;
}

.font-semibold {
  font-weight: 600;
}

.w-13 {
  width: 33.333333%;
}

.mb-4 {
  margin-bottom: 1rem;
}
</style>
