<template>
  <div class="box">
    <div class="wrapper">
      <div class="banner-image">
        <video
          class="card_images"
          autoplay
          loop
          muted
          ref="video"
          @loadeddata="onVideoLoad"
        >
          <source :src="loadVideo(link)" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <h1>{{ myNfts }} Vourcher</h1>
    </div>
    <div class="button-wrapper">
      <div class="form-group">
        <input
          type="number"
          v-model="amount"
          class="form-control outline"
          placeholder="amount"
        />
      </div>
      <br />
      <button class="btn fill" @click="redeem()">Redeem</button>
    </div>
  </div>
</template>

<script>
import contractService from "../services";
import { account } from "@kolirt/vue-web3-auth";
export default {
  name: "VideoBlock",
  props: ["link"],
  data() {
    return {
      loading: true,
      tempImg: true,
      myNfts: 0,
      amount: 0,
    };
  },
  mounted() {
    setTimeout(async () => {
      this.myNfts = await contractService.getNFTs();
      console.log(this.myNfts);
      this.loading = false;
    }, 2000);
  },
  methods: {
    async redeem() {
      await contractService.callContractFindex(
        "createFund",
        [3, "Bitcoin", 60000, 30],
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getUrl() {
      return "https://ipfs.io/ipfs/QmNiN3znaMwobtd3LqMERvmsmThnykwx9SncFgUJKcsQgo";
    },
    onVideoLoad() {
      // const video = this.$refs.video;
      this.tempImg = false;
      // if (video.readyState === 4) {
      // }
    },
    loaded() {
      this.loading = false;
    },
    loadVideo(link) {
      return link;
    },
  },
};
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
}
.multiple_cards img {
  height: 100%;
  width: 100%;
  padding: 2px 6px;
  border-radius: 12px;
}
.lg-card img {
  height: 100%;
  width: 100%;
  /* padding: 4px 8px; */
}
.dyn-img {
  aspect-ratio: 0 !important;
}
.inv-page .optionl_img {
  width: 106px;
  left: 8px;
  top: 7px;
  height: 172px;
}
.market-card .optionl_img {
  width: 115px;
  height: 161px;
  top: 35px;
  left: 48px;
}
.user-gun .optionl_img {
  width: 91px;
  height: 151px;
  top: 12px;
  left: 135px;
}
.user-chars .optionl_img {
  width: 87px;
  height: 147px;
  top: 12px;
  left: 11px;
}
.nftInner .optionl_img {
  width: 114px;
  height: 181px;
  top: 10px;
  left: 25px;
}
.refil-nft .optionl_img {
  width: 114px;
  height: 181px;
  top: 10px;
  left: 62px;
}
@media (max-width: 768px) {
  .nftInner .optionl_img {
    left: 14px;
  }
}
.box {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.25);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 38px;
  filter: drop-shadow(0 30px 10px rgba(0, 0, 0, 0.125));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.banner-image {
  background-image: url("../../public/images/loader.gif");
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.255);
}

h1 {
  font-family: "Righteous", sans-serif;
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  font-size: 1.4rem;
}

p {
  color: #fff;
  font-family: "Lato", sans-serif;
  text-align: center;
  font-size: 0.8rem;
  line-height: 150%;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-wrapper {
  margin-top: 18px;
}

.btn {
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 12px;
  font-size: 0.8rem;
  letter-spacing: 2px;
  cursor: pointer;
}

.btn + .btn {
  margin-left: 10px;
}

.outline {
  background: transparent;
  color: rgba(0, 212, 255, 0.9);
  border: 1px solid rgba(0, 212, 255, 0.6);
  transition: all 0.3s ease;
}

.outline:hover {
  transform: scale(1.125);
  border-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

.fill {
  background: rgba(0, 212, 255, 0.9);
  color: rgba(255, 255, 255, 0.95);
  filter: drop-shadow(0);
  font-weight: bold;
  transition: all 0.3s ease;
}

.fill:hover {
  transform: scale(1.125);
  border-color: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.125));
  transition: all 0.3s ease;
}
</style>
