<template>
  <div>
    <Navbar />
    <router-view v-slot="slotProps">
      <transition name="route" mode="out-in">
        <div>
          <component :is="slotProps.Component"></component>
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
export default {
  name: "mainView",
  components: {
    Navbar,
  },
};
</script>
