<script setup>
import { defineProps, ref, watch } from "vue";
const props = defineProps(["currentTokenPrices"]);

const fundInfo = ref(props.currentTokenPrices);

watch(
  () => props.currentTokenPrices,
  (newValue) => {
    if (newValue) {
      fundInfo.value = newValue;
    }
  }
);

function formatNumber(number) {
  return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

function priceFormat(price) {
  if (price < 0) return price.toFixed(2);
  if (price < 0.01) {
    return price.toFixed(8);
  } else if (price > 0 && price < 1) {
    return price.toFixed(2);
  } else if (price >= 1000) {
    return formatNumber(price);
  } else {
    return price.toFixed(2);
  }
}
</script>
<template>
  <div class="table-responsive">
    <table class="table tbl">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Invested Price</th>
          <th scope="col">Current Price</th>
          <th scope="col">Change</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in fundInfo" :key="index">
          <td>{{ item.name }}</td>
          <td>${{ priceFormat(item.investedPrice) }}</td>
          <td>${{ priceFormat(item.currentPrice) }}</td>
          <td>
            <span class="ms-1" :class="item.percentageChange > 0 ? '' : 'text-danger'"
              ><i
                class="fa"
                :class="
                  item.percentageChange > 0
                    ? 'fa-angle-up text-success'
                    : 'fa-angle-down text-danger'
                "
                aria-hidden="true"
              ></i>
              {{ priceFormat(item.percentageChange) }}%</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.tbl {
  --bs-table-bg: transparent;
}
.tbl th,
.tbl td {
  color: #fff;
  padding: 10px 0;
}
.tbl th {
  font-size: 14px;
}
.tbl td {
  font-size: 13px;
}
.fa-angle-up,
.fa-angle-down {
  font-size: 18px;
}
</style>
