<template>
  <div class="container mb-5 mt-3">
    <Loader v-if="pageLoader" />
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="right-side-content bg-dark-50">
          <div class="form-group drpd-wrapper">
            <select class="form-control" v-model="drpValue">
              <option value="">Select token</option>
              <option
                v-for="(item, index) in dropdownData"
                :key="index"
                :value="item._id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="text-center mt-4" v-if="isLoading">
            <IsLoader />
          </div>
          <div v-else-if="Object.keys(info).length > 0">
            <h4 class="voucher-title">Performance {{ info?.fundName }}</h4>
            <ul class="mt-3 ps-0">
              <li class="d-flex align-items-center">
                <div class="bg-pink rounded"></div>
                <div class="ms-3">
                  <p class="mb-0">
                    Voucher Principle: {{ priceFormat(info.investedCredit) }}
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-center">
                <div class="bg-blue-300 rounded"></div>
                <p class="ms-3 mb-0">Tokens in fund: {{ info.totalInFund }}</p>
              </li>
              <li class="d-flex align-items-center">
                <div class="bg-yellow-200 rounded"></div>
                <p class="ms-3 mb-0">
                  Current P/L:
                  <span class="ms-1" :class="info.currentPL > 0 ? '' : 'text-danger'"
                    ><i
                      class="fa"
                      :class="
                        info.currentPL > 0
                          ? 'fa-angle-up text-success'
                          : 'fa-angle-down text-danger'
                      "
                      aria-hidden="true"
                    ></i
                    >{{ priceFormat(info.currentPL) }}%</span
                  >
                </p>
              </li>
              <li class="d-flex align-items-center">
                <div class="bg-yellow-200 rounded"></div>
                <p class="ms-3 mb-0">
                  Current FDXD Earning:
                  {{ priceFormat(info.profit) }}
                </p>
              </li>
            </ul>
            <FundDetailsTable :currentTokenPrices="currentTokenPrices" />
            <div class="button-list w-100">
              <button class="bg-success cos-btn d-block" @click="fundClosed()">
                Close Fund
              </button>
            </div>
          </div>
          <p v-if="funds.length === 0 && !isLoading">
            No fund found! create a fund by clicking Create fund page.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, watchEffect } from "vue";
import FundDetailsTable from "../components/FundsDetailsTable.vue";
import { account } from "@kolirt/vue-web3-auth";
import { useDefaultStore, useUserBalanceStore } from "@/stores/index";
import contractService from "../services";
import ApiService from "../services/index";
import Loader from "@/components/loader.vue";
import IsLoader from "@/components/IsLoader.vue";

const funds = ref([]);
const drpValue = ref("");
const isLoading = ref(false);
const info = ref([]);
const dropdownData = ref([]);
const currentTokenPrices = ref([]);
const pageLoader = ref(false);

const store = useDefaultStore();
const balanceStore = useUserBalanceStore();

watch(
  () => store.data && account.address,
  async (newVal) => {
    if (newVal && account.connected) {
      try {
        await fetchFunds();
      } catch (error) {
        isLoading.value = false;
      }
    }
  },

  { immediate: true }
);

watchEffect(() => {
  const selectedTokenId = drpValue.value;

  if (selectedTokenId !== undefined && selectedTokenId !== null) {
    const selectedTokenDetails = funds.value.find(
      (token) => token.id === selectedTokenId
    );

    if (selectedTokenDetails) {
      const tokenPrices = funds.value.filter((token) => token.id === selectedTokenId);

      currentTokenPrices.value = tokenPrices;
      info.value = selectedTokenDetails;
    } else {
      // Handle case where no matching token details are found
      currentTokenPrices.value = [];
      info.value = {};
    }
  } else {
    // Handle case where selectedTokenId is undefined or null
    currentTokenPrices.value = [];
    info.value = {};
  }
});

function filterCryptoData(symbol) {
  const record = store.data.find((crypto) => crypto.symbol === symbol);
  if (record === undefined) {
    return { price: 0 };
  }
  return record;
}

async function fetchFunds() {
  try {
    isLoading.value = true;
    const res = await ApiService.getMyFunding(account.address);
    if (res?.statusCode === 200 && res.data.length > 0) {
      drpValue.value = res.data[0]._id;
      dropdownData.value = res.data;
      funds.value = res.data
        .map((row) => {
          return row.cryptoNames.map((key) => {
            const { currentPL, runningProfitFDXD, change } = rewardCalculation(row);
            const info = filterCryptoData(key.name);
            const percentageChange =
              ((Number(info.price) - Number(key.investedPrice)) /
                Number(key.investedPrice)) *
              100;
            return {
              id: row._id,
              tokenId: row.tokenId,
              totalInFund: row.cryptoNames.length,
              currentPL: currentPL,
              fundName: row.name || "",
              investedCredit: row.investedCredit,
              name: key.name,
              currentPrice: info.price,
              investedPrice: key.investedPrice,
              percentageChange: percentageChange,
              change: change,
              profit: runningProfitFDXD,
            };
          });
        })
        .flat();
    }
  } catch (error) {
    ApiService.errorMsg(error.message);
  } finally {
    isLoading.value = false;
  }
}
const rewardCalculation = (dbFund) => {
  const credits = dbFund.investedCredit;
  const crypto = dbFund.cryptoNames;
  let change = 0;
  for (const coin of crypto) {
    const info = filterCryptoData(coin.name);
    if (info !== undefined) {
      const investedPrice = coin.investedPrice;
      const percentageChange =
        (Number(info.price - investedPrice) / Number(investedPrice)) * 100;
      change += percentageChange;
    }
  }

  const currentPL = change / crypto.length;
  const runningProfitFDXD = currentPL > 0 ? Number(credits) * (currentPL / 100) : 0;

  return { runningProfitFDXD, currentPL, change };
};
const getBalances = async (account) => {
  balanceStore.fetchBalance(account);
};
async function fundClosed() {
  try {
    pageLoader.value = true;
    const result = await contractService.getFundStatus(
      account.address,
      info.value.tokenId
    );
    if (result[2] === true) {
      await ApiService.getReward(account.address, info.value.tokenId);
      pageLoader.value = false;
    } else {
      await contractService.callContractFindex(
        "closeFund",
        [info.value.tokenId],
        async (tx) => {
          if (tx.status === "success") {
            await ApiService.getReward(account.address, info.value.tokenId);
            info.value = [];
            await fetchFunds();
            getBalances(account.address);
          }
          pageLoader.value = false;
        },
        ApiService.errorMsg
      );
    }
  } catch (error) {
    ApiService.errorMsg(error.message);
  } finally {
    pageLoader.value = false;
  }
}
async function fundCancel() {
  try {
    pageLoader.value = true;
    await contractService.callContractFindex(
      "unstake",
      [account.address, info.value.tokenId],
      async (tx) => {
        if (tx.status === "success") {
          await ApiService.removeFund(info.value.id);
          await fetchFunds();
          info.value = [];
        }
        pageLoader.value = false;
      },
      ApiService.errorMsg
    );
  } catch (error) {
    ApiService.errorMsg(error);
  } finally {
    pageLoader.value = false;
  }
}
function formatNumber(number) {
  if (number)
    return Number(number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  else return number;
}

function priceFormat(price) {
  if (price < 0) return price.toFixed(2);
  if (price < 0.01) {
    return price.toFixed(8);
  } else if (price > 0 && price < 1) {
    return price.toFixed(2);
  } else if (price >= 1000) {
    return formatNumber(price);
  } else {
    return price.toFixed(2);
  }
}
</script>

<style scoped>
.fund-name {
  width: 30%;
  margin: 0 auto 30px auto;
  padding: 12px;
  border: 2px solid;
  text-align: center;
  border-radius: 5px;
}

.drpd-wrapper select {
  border: none;
  border-radius: 8px;
  padding: 9px 10px;
  margin-bottom: 20px;
}
.table-wrapper {
  height: auto;
}
.left-side-content {
  padding: 4px 40px;
  border-radius: 8px;
}
.table > :not(caption) > * > * {
  background-color: inherit !important;
}
.bg-caribbean-green {
  background: #00dbaa;
}
.bg-red {
  background: #db0000;
}
.table-wrapper .table tr {
  border-bottom: 1px solid #979797;
  cursor: pointer;
}
.table th,
td {
  color: var(--whiteColor) !important;
  text-align: center;
  font-family: var(--BarlowFont);
}
.table th {
  font-size: 17px;
  font-weight: 600;
  padding: 20px 0;
}
.table td {
  padding: 20px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
}
.bottom-content__list {
  display: flex;
  align-items: self-start;
}
.right-side-content {
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 34px 38px 10px 26px;
  width: 40%;
  margin: auto;
}
.rounded {
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
}
.bg-pink {
  background: #ff7ca3;
}
.bg-blue-300 {
  background: #7cb0ff;
}
.bg-yellow-200 {
  background: #c5ff7c;
}
.right-side-content ul li {
  margin-bottom: 2rem;
}
</style>
