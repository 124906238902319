<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg main-navbar">
      <a href="#" class="navbar-brand">
        <img src="/images/banner-logo.png" class="img-fluid" alt="logo" />
      </a>
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavId"
        aria-controls="collapsibleNavId"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="mobileShow = !mobileShow"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-between w-100"
        :class="mobileShow ? 'show' : ''"
        id="collapsibleNavId"
      >
        <ul class="navbar-nav ms-auto navbar-text-wrapper">
          <li
            class="nav-item"
            :class="$route.meta.sideBarKey == 'CreateFund' ? 'active' : ''"
            @click="createActive('fund')"
          >
            <router-link to="/create-fund"> Create Funds </router-link>
          </li>
          <li
            class="nav-item"
            :class="$route.meta.sideBarKey == 'Performance' ? 'active' : ''"
            @click="createActive('perf')"
          >
            <router-link to="/performance"> Performance </router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item d-flex align-items-center me-3">
            <span><img src="/images/findxd.png" class="findexd-icon" alt="" /></span>
            <span class="ms-2">{{ balanceStore.balance }} FDXD</span>
          </li>
          <li class="nav-item dropdown" @click="showNotificationDropdown">
            <a href="#" class="notification-wrapper">
              <img src="/images/icons_bell.png" alt="" />
            </a>
            <div
              class="dropdown-menu notification-dropdown"
              v-if="isOpen"
              :class="isOpen ? 'show' : ''"
            >
              <a class="dropdown-item" href="#">Action</a>
              <h6 class="dropdown-header">Section header</h6>
              <a class="dropdown-item" href="#">Action</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">After divider action</a>
            </div>
          </li>

          <li class="nav-item profile-wrapper d-flex align-items-center dropdown">
            <a href="#" v-if="walletInfo && walletInfo.connected" @click="toggleDropdown">
              <span class="pro-title">{{ walletInfo.shortAddress }}</span>
              <span
                ><i class="fa fa-chevron-down text-white" aria-hidden="true"></i
              ></span>
            </a>
            <a href="#" v-else>
              <span class="pro-title">
                <button @click="disconnect" class="btn btn-success">Reconnect</button>
              </span>
              <span
                ><i class="fa fa-chevron-down text-white" aria-hidden="true"></i
              ></span>
            </a>
            <div
              class="dropdown-menu show"
              aria-labelledby="myDropdown"
              v-if="isDropdownOpen"
            >
              <a class="dropdown-item" href="#" @click="disconnect">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, watch } from "vue";
import {
  $on,
  $off,
  account,
  disconnect as masterDisconnect,
  connect as masterConnect,
  Events,
} from "@kolirt/vue-web3-auth";
import { useUserBalanceStore } from "@/stores/index";
const balanceStore = useUserBalanceStore();
const isDropdownOpen = ref(false);
const isOpen = ref(false);
const mobileShow = ref(false);
const walletInfo = ref(account);
const isActive = ref("fund");
const route = useRouter();

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

function showNotificationDropdown() {
  isOpen.value = !isOpen.value;
}

watch(
  () => account.connected,
  async (newVal) => {
    if (newVal) {
      balanceStore.fetchBalance(account.address);
    }
  }
);

const createActive = (active) => {
  isActive.value = active;
};

const disconnect = async () => {
  const handler = () => {
    $off(Events.Disconnected, handler);
  };

  $on(Events.Disconnected, handler);

  await masterDisconnect().catch(() => {
    $off(Events.Disconnected, handler);
  });
  isDropdownOpen.value = false;
  route.push("/login");
};
// const reconnect = async () => {
//   await masterDisconnect();
//   await masterConnect();
// };
</script>

<style scoped>
.findexd-icon {
  width: 20px;
  object-fit: contain;
}

.notification-wrapper {
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, #393939 0%, #1d1e22 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
.notification-wrapper img {
  width: 20px;
}

.notification-dropdown {
  width: 270px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
}

.navbar-text-wrapper .nav-item a:last-child {
  margin-left: 40px;
}
.navbar-text-wrapper .nav-item.active a {
  font-weight: 800;
  color: #00b9ae;
  position: relative;
}
.navbar-text-wrapper .nav-item.active a::after {
  content: "";
  position: absolute;
  display: block;
  width: 110px;
  height: 3px;
  top: 25px;
  left: 0;
  background: #00b9ae;
  border-radius: 50px;
}
.navbar-text-wrapper .nav-item a {
  color: #f9f9f9;
  font-family: var(--LatoFont);
  font-size: 18px;
  font-weight: 400;
  line-height: 100%; /* 21.666px */
}

.profile-wrapper a .pro-title {
  color: #fff;
  font-family: var(--LatoFont);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 20px;
}

.profile-wrapper .dropdown-menu {
  min-width: 100%;
  margin-top: 80px;
}
.dropdown-menu .dropdown-item:hover {
  background: transparent !important;
  color: rgb(145, 134, 134);
  transition: color 0.4s;
}

.navbar-collapse {
  flex-grow: unset;
}
</style>
