import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { Chains, createWeb3Auth } from '@kolirt/vue-web3-auth';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { createPinia } from 'pinia';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const pinia = createPinia();

const amoy = {
  id: 80002,
  name: 'Polygon testnet',
  network: 'matic',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    default: {
      http: [
        'https://polygon-amoy.g.alchemy.com/v2/cI-EswXboNlZydd3ZZ5tXHUuiayA8yWd',
      ],
      webSocket: ['wss://rpc-amoy.polygon.technology'],
    },
    public: {
      http: [' https://rpc-amoy.polygon.technology'],
      webSocket: ['wss://rpc-amoy.polygon.technology'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'Polygon',
      url: 'https://amoy.polygonscan.com/',
    },
    default: {
      name: 'Polygon explorer',
      url: 'https://amoy.polygonscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 15921452,
    },
  },
};
const app = createApp(App);
app
  .use(pinia)
  .use(Toast)
  .use(router)
  .use(
    createWeb3Auth({
      // enableCustomProvider: true,
      projectId: '3c5c8069ff37304cc62e07ae8cb592a8',
      chains: [
        // Chains.mainnet,
        amoy,
      ],
    })
  )
  .mount('#app');
