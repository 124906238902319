import Web3 from "web3";
import {
  account,
  writeContract,
  readContract,
  signMessage,
  fetchBalance,
} from "@kolirt/vue-web3-auth";
import creditABI from "../stores/credit.abi.json";
import voucherABI from "../stores/voucher.abi.json";
import findexABI from "../stores/findexd.abi.json";
import { useToast } from "vue-toastification";
import axios from "axios";

const voucherAddress = process.env.VUE_APP_NFT;
const creditAddress = process.env.VUE_APP_TOKEN;
const findexAddress = process.env.VUE_APP_FINDEXD;
const backendUrl = process.env.VUE_APP_BACKEND_API;
const toast = useToast();
const web3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_RPC));
const authHeader = {
  headers: {
    "Content-Type": "application/json",
    signature: localStorage.getItem("signature"),
    account: localStorage.getItem("account"),
  },
  withCredentials: true,
};
const successMsg = (msg) => {
  toast.success(msg || "Transaction successful");
};

const errorMsg = (error) => {
  if (typeof error === "string") {
    toast.error(error);
  } else if (typeof error === "object" && error.message) {
    toast.error(error.message);
  } else {
    toast.error("Something went wrong. Transaction failed.");
  }
};

const approveVoucher = async (token) => {
  try {
    return await readContract({
      abi: voucherABI,
      address: voucherAddress,
      functionName: "getApproved",
      args: [token],
    });
  } catch (e) {
    errorMsg(e);
  }
};
const getFundStatus = async (address, token) => {
  try {
    return await readContract({
      abi: findexABI,
      address: findexAddress,
      functionName: "lockedNFTs",
      args: [address, token],
    });
  } catch (e) {
    errorMsg(e);
  }
};

async function getBalance(account) {
  try {
    if (account) {
      const creditContract = new web3.eth.Contract(creditABI, creditAddress);

      const balance = await creditContract.methods.balanceOf(account).call();
      const formattedBalance =
        web3.utils.fromWei(balance, "wei") / Math.pow(10, 18);
      return formattedBalance > 0 ? Number(formattedBalance).toFixed(2) : 0.0;
    }
  } catch (e) {
    console.log("e", e);
  }
}
async function login(account, callBack) {
  try {
    const message = "Login to the application"; // Message to be signed
    const signature = await signMessage(message);
    const response = await fetch(`${backendUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message, signature, account }),
    });
    if (response.ok) {
      const data = await response.json();
      if (data.success) {
        callBack(signature);
      } else {
        errorMsg("Login failed");
      }
    } else {
      errorMsg("Login failed");
    }
  } catch (error) {
    console.error("Error:", error);
  }
}
async function getNFTs() {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const voucherContract = new web3.eth.Contract(voucherABI, voucherAddress);
      const balance = await voucherContract.methods
        .balanceOf(account.address, 1)
        .call();
      return balance;
    }
  } catch (e) {
    errorMsg("Something went wrong!");
    console.log("e", e);
  }
}

async function getUserBalance(addr, token) {
  try {
    let params = { address: addr };
    if (token) {
      params.token = token;
    }
    return await fetchBalance(params);
  } catch (e) {
    errorMsg(e);
    console.log("e", e);
  }
}

async function approveAndCreateFund(params, successCall, errorCall) {
  try {
    const result = await writeContract({
      abi: voucherABI,
      address: voucherAddress,
      functionName: "approve",
      args: [findexAddress, params[0]],
    });
    const response = await result.wait();
    if (response.status === "success") {
      return await callContractFindex(
        "createFund",
        params,
        successCall,
        errorCall
      );
    }
  } catch (e) {
    errorCall(e);
    errorMsg(e);
    console.log("e", e);
  }
}

async function createFund(params, successCall, errorCall) {
  try {
    const address = await approveVoucher(params[0]);
    if (address.toLowerCase() === findexAddress.toLowerCase()) {
      return await callContractFindex(
        "createFund",
        params,
        successCall,
        errorCall
      );
    } else {
      return await approveAndCreateFund(params, successCall, errorCall);
    }
  } catch (e) {
    errorCall(e);
    errorMsg(e);
    console.log("e", e);
  }
}

async function callContractNFT(action, params, errorCall) {
  try {
    const data = await writeContract({
      abi: voucherABI,
      address: voucherAddress,
      functionName: action,
      args: params,
    });
    console.log("hash", data.hash);
    await data.wait();
  } catch (e) {
    errorMsg(e.message);
    console.log("e", e);
  }
}
async function callContractFindex(action, params, successCall, errorCall) {
  try {
    const res = await writeContract({
      abi: findexABI,
      address: findexAddress,
      functionName: action,
      args: params,
    });
    const tx = await res.wait();
    successCall(tx);
  } catch (e) {
    errorCall(e.message);
    console.log("e", e.message);
  }
}

async function getMyNFTs(accountAddress) {
  const config = {
    method: "GET",
    url: `${process.env.VUE_APP_RPC}/getNFTsForOwner?owner=${accountAddress}&contractAddresses[]=${voucherAddress}&withMetadata=true`,
  };

  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    errorMsg(error);
    console.log(error);
  }
}

async function getMyFunding(accountAddress) {
  const config = {
    method: "GET",
    url: `${backendUrl}/get-fund/${accountAddress}`,
    headers: {
      "Content-Type": "application/json",
      signature: localStorage.getItem("signature"),
      account: localStorage.getItem("account"),
    },
    withCredentials: true,
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    errorMsg(error.message);
    console.log(error);
  }
}
async function removeFund(id) {
  const config = {
    method: "POST",
    url: `${backendUrl}/remove-fund/${id}`,
    headers: {
      "Content-Type": "application/json",
      signature: localStorage.getItem("signature"),
      account: localStorage.getItem("account"),
    },
    withCredentials: true,
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    errorMsg(error);
  }
}

async function postCreateFund(investor, tokenId, cryptoNames, investedCredit) {
  const data = {
    investor: investor,
    tokenId: tokenId,
    cryptoNames: cryptoNames,
    investedCredit: investedCredit,
    investmentStartTime: Math.floor(new Date().getTime() / 1000),
  };
  const config = {
    method: "POST",
    url: `${backendUrl}/createFund`,
    headers: {
      "Content-Type": "application/json",
      signature: localStorage.getItem("signature"),
      account: localStorage.getItem("account"),
    },
    withCredentials: true,
    data: data,
  };
  try {
    const res = await axios(config);
    if (res.status === 200) {
      successMsg("Fund saved successfully");
      return res;
    }
  } catch (error) {
    errorMsg(error);
  }
}
async function getReward(investor, tokenId, cryptoNames) {
  const config = {
    method: "POST",
    url: `${backendUrl}/closeFund`,
    headers: {
      "Content-Type": "application/json",
      signature: localStorage.getItem("signature"),
      account: localStorage.getItem("account"),
    },
    withCredentials: true,
    data: {
      investor: investor,
      tokenId: tokenId,
    },
  };
  try {
    const result = await axios(config);
    const res = result.data;
    if (res.statusCode === 200) {
      if (res.message === "Failed to closed") {
        errorMsg(
          "There might issue with network, fund cannot be close right now."
        );
      } else {
        successMsg("Fund closed successfully");
      }
      return res;
    }
  } catch (error) {
    errorMsg(error);
    console.log(error);
  }
}

export default {
  getMyNFTs,
  removeFund,
  getMyFunding,
  getBalance,
  callContractNFT,
  getNFTs,
  errorMsg,
  successMsg,
  createFund,
  getReward,
  getFundStatus,
  callContractFindex,
  getUserBalance,
  login,
  postCreateFund,
};
