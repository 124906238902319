import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
// import ApiService from '../services';
import mainView from '../components/Main';
import LoginLayout from '../components/LoginLayout';
import Performance from '../views/perfomance.vue';
import Inventory from '../views/inventory.vue';
const routes = [
  {
    path: '/',
    redirect: '/create-fund',
    component: mainView,
    // beforeEnter(to, from, next) {
    //     if (!isLogin()) {
    //         next('/login');
    //     } else {
    //         next();
    //     }
    // },
    children: [
      {
        path: '/create-fund',
        name: 'home',
        props: true,
        component: Home,
        meta: {
          sideBarKey: 'CreateFund',
        },
      },
    ],
  },
  {
    path: '/login',
    component: LoginLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          sideBarKey: 'login',
        },
      },
    ],
  },
  {
    path: '/performance',
    component: mainView,
    children: [
      {
        path: '/performance',
        name: 'performance',
        component: Performance,
        meta: {
          sideBarKey: 'Performance',
        },
      },
    ],
  },
  {
    path: '/inventory',
    component: mainView,
    children: [
      {
        path: '/inventory',
        name: 'inventory',
        component: Inventory,
        meta: {
          sideBarKey: 'inventory',
        },
      },
    ],
  },
];
// const isLogin = function () {
//     return ApiService.isLogin();
// };
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
