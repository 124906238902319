<template>
  <div class="bg-color wrapper">
    <div class="top-nav">
      <div class="container">
        <div class="logo">
          <img src="/images/banner-logo.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="login-left-content">
            <div class="top-left-img">
              <img src="/images/top-banner_bg.png" class="top-left-icon" alt="" />
              <img src="/images/banner-ring1.png" class="" alt="" />
            </div>
            <div class="left-wallet-img">
              <img src="/images/wallet.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="login-right-wrapper">
            <div class="login-text">
              <h3 class="login-title">Login</h3>
              <p class="my-4">Welcome onboard with us!</p>
            </div>
            <h6 class="mt-4" v-if="walletInfo && walletInfo.connected">
              <button type="button" class="mt-4 btn btn-success" @click="goHome()">
                Home
              </button>
            </h6>
            <div class="login-button" v-else>
              <h4 class="mt-4 btn btn-success" @click="connect()">
                {{ loading.connecting ? "Connecting..." : "CONNECT A WALLET" }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, watch } from "vue";
import { useRouter } from "vue-router";
import {
  $off,
  $on,
  account,
  chain,
  connect as masterConnect,
  disconnect as masterDisconnect,
  Events,
  getAvailableChains,
} from "@kolirt/vue-web3-auth";
import ApiService from "../services/index";

export default {
  name: "LoginView",
  setup() {
    const loading = reactive({
      connecting: false,
      connectingTo: {},
      switchingTo: {},
      logouting: false,
    });

    const walletInfo = account;
    const router = useRouter();

    const chains = computed(() => getAvailableChains());
    const availableChains = computed(() =>
      chains.value.filter((item) => item.id !== chain.value.id)
    );

    const connect = async (chain) => {
      const handler = async (state) => {
        if (!state) {
          loading.connecting = false;
          $off(Events.ModalStateChanged, handler);
        }
      };

      $on(Events.ModalStateChanged, handler);
      loading.connecting = true;
      await masterConnect(chain);
    };

    const disconnect = async () => {
      loading.logouting = true;

      const handler = () => {
        loading.logouting = false;
        $off(Events.Disconnected, handler);
      };

      $on(Events.Disconnected, handler);

      await masterDisconnect().catch(() => {
        loading.logouting = false;
        $off(Events.Disconnected, handler);
      });
    };

    const reconnect = async (newChain) => {
      if (chain.value.id !== newChain.id) {
        await masterDisconnect();
        await masterConnect(newChain);
      }
    };

    const goHome = () => {
      router.push("/create-fund");
    };

    watch(walletInfo, async (newVal, oldVal) => {
      if (newVal.connected) {
        await ApiService.login(account.address, (signature) => {
          localStorage.setItem("account", account.address);
          localStorage.setItem("signature", signature);
          router.push("/create-fund");
        });
      }
    });
    return {
      loading,
      walletInfo,
      chains,
      availableChains,
      connect,
      disconnect,
      reconnect,
      goHome,
    };
  },
};
</script>

<style scoped>
#ual-modal #ual-modal-get-authenticator,
#ual-modal-selection-content,
#ual-modal-input-content {
  background: transparent !important;
}

.top-left-img {
  position: relative;
  top: 35px;
}
.wrapper {
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  /* padding-bottom: 50px; */
}
.bg-color {
  /* border-radius: 1401.686px; */
  background: rgba(6, 125, 113, 0.65);
}

.wrapper::before {
  content: "";
  width: 300px;
  height: 300px;
  position: absolute;
  top: -108px;
  right: -27px;
  border-radius: 50%;
  border: 1px solid #ddd;
  z-index: -1;
}
</style>
