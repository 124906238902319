<script setup>
import contractService from "../services";
import { account } from "@kolirt/vue-web3-auth";
import ApiService from "../services/index";
import Loader from "@/components/loader";
import { useDefaultStore } from "@/stores/index";
import IsLoader from "@/components/IsLoader";
import { watch, ref } from "vue";

const items = ref([]);
const isShowFilterDropdown = ref(false);
const loading = ref(true);
const searchText = ref("");
const nfts = ref([]);
const isLoading = ref(true);
const selectedCoin = ref([]);
const selectedNFT = ref("");
const selectedNFTName = ref("");
const walletAddress = ref("");
const pageLoader = ref(false);
const investedCredit = ref(null);
const originalItems = ref([]);

const store = useDefaultStore();
const { storeLoader } = store;

watch(
  () => account.connected,
  async (newVal) => {
    if (newVal) {
      try {
        const res = await ApiService.getMyNFTs(account.address);
        walletAddress.value = account.address;
        nfts.value = res.ownedNfts;
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
      }
    }
  },
  { immediate: true }
);
watch(
  () => store.data,
  async (newVal) => {
    if (newVal) {
      try {
        originalItems.value = store.data;
        items.value = originalItems.value.slice();
        loading.value = false;
      } catch (error) {
        isLoading.value = false;
      }
    }
  },
  { immediate: true }
);
async function createFund() {
  try {
    if (selectedCoin.value.length && selectedNFT.value) {
      pageLoader.value = true;
      const params = [selectedNFT.value, selectedCoin.value];
      await contractService.createFund(
        params,
        async (tx) => {
          if (tx.status === "success") {
            await saveCreatedFund();
            pageLoader.value = false;
            await ApiService.getMyNFTs(account.address);
            selectedCoin.value = [];
            selectedNFT.value = "";
            selectedNFTName.value = "";
          } else {
            ApiService.errorMsg("An error occurred while creating the fund.");
          }
        },
        (res) => {
          pageLoader.value = false;
        }
      );
    } else {
      ApiService.errorMsg("Please select a token and NFT first.");
    }
  } catch (error) {
    ApiService.errorMsg(error);
  } finally {
    pageLoader.value = false;
  }
}

const saveCreatedFund = async () => {
  try {
    await contractService.postCreateFund(
      walletAddress.value,
      selectedNFT.value,
      selectedCoin.value,
      investedCredit.value
    );
  } catch (error) {
    ApiService.errorMsg(error);
  }
};

async function setTokenSymbol(symbol) {
  if (!selectedCoin.value.includes(symbol)) {
    if (selectedCoin.value.length < 3) {
      selectedCoin.value.push(symbol);
    }
  }
}

function removeTokenSymbol(symbol) {
  const index = selectedCoin.value.indexOf(symbol);
  if (index !== -1) {
    selectedCoin.value.splice(index, 1);
  }
}

async function setTokenId(id, name, creditList) {
  selectedNFT.value = id;
  selectedNFTName.value = name;
  const credit = creditList.find((value) => value.trait_type === "credit");
  if (credit) {
    investedCredit.value = credit.value;
  }
}
function searchItems() {
  if (searchText.value.trim() === "") {
    items.value = originalItems.value.slice();
  } else {
    items.value = originalItems.value.filter((item) => {
      return item.name.toLowerCase().includes(searchText.value.toLowerCase());
    });
  }
}

function applyFilter(filterType) {
  switch (filterType) {
    case "priceHighToLow":
      items.value.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
      break;
    case "priceLowToHigh":
      items.value.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      break;
    case "marketCapHighToLow":
      items.value.sort((a, b) => parseFloat(b.market_cap) - parseFloat(a.market_cap));
      break;
    case "marketCapLowToHigh":
      items.value.sort((a, b) => parseFloat(a.market_cap) - parseFloat(b.market_cap));
      break;
  }
  isShowFilterDropdown.value = false;
}

function formatNumber(number) {
  return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}
function formatPrice(number) {
  return number.toFixed(5).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}
function toggleFilterDropdown() {
  isShowFilterDropdown.value = !isShowFilterDropdown.value;
}
function formatToMillionNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(2) + "B";
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + "M";
  } else {
    return num.toString();
  }
}

function priceFormat(price) {
  if (price < 0) return price.toFixed(2);
  if (price < 0.01) {
    return price.toFixed(8);
  } else if (price > 0 && price < 1) {
    return price.toFixed(2);
  } else if (price >= 1000) {
    return formatNumber(price);
  } else {
    return price.toFixed(2);
  }
}
</script>

<template>
  <div class="container mb-5">
    <Loader v-if="pageLoader" />
    <div class="row">
      <div class="col-md-8">
        <section class="crypto-content-wrapper">
          <div class="top-bar">
            <div class="top-bar__search-bar">
              <span>
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
              <input
                type="text"
                v-model="searchText"
                @input="searchItems"
                placeholder="Search anything..."
              />
            </div>
            <div
              class="top-bar__filter-btn ms-2"
              @mouseenter="toggleFilterDropdown(true)"
              @mouseleave="toggleFilterDropdown(false)"
            >
              <button>
                <span class="me-2">
                  <img src="/images/option.png" alt="" class="img-fluid" />
                </span>
                Filter Order
              </button>
              <div v-if="isShowFilterDropdown" class="filter-dropdown">
                <button @click="applyFilter('priceHighToLow')">Price High to Low</button>
                <button @click="applyFilter('priceLowToHigh')">Price Low to High</button>
                <button @click="applyFilter('marketCapHighToLow')">
                  Market Cap High to Low
                </button>
                <button @click="applyFilter('marketCapLowToHigh')">
                  Market Cap Low to High
                </button>
              </div>
            </div>
          </div>

          <div class="table-wrapper table-responsive mt-4 pe-3">
            <div
              class="d-flex justify-content-center align-items-center h-100"
              v-if="storeLoader"
            >
              <IsLoader />
            </div>
            <table class="table" v-else>
              <thead>
                <tr>
                  <th>
                    <label for="myCheckbox01" class="checkbox">
                      <input class="checkbox__input" type="checkbox" id="myCheckbox01" />
                      <svg
                        class="checkbox__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 22"
                      >
                        <rect
                          width="18"
                          height="18"
                          x=".5"
                          y=".5"
                          fill="#0c3835"
                          stroke="#fff"
                          rx="3"
                        />
                        <path
                          class="tick"
                          stroke="#6EA340"
                          fill="none"
                          stroke-linecap="round"
                          stroke-width="2"
                          d="M3 10l5 5 8-8"
                        />
                      </svg>
                    </label>
                  </th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>7D%</th>
                  <th>Market Cap</th>
                  <th>24 H Volume</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr
                  :class="selectedCoin.includes(item.symbol) ? 'selected' : ''"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td>
                    <div
                      v-if="selectedCoin.includes(item.symbol)"
                      class="add-row-wrapper btn btn-success"
                      @click="removeTokenSymbol(item.symbol)"
                    >
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    </div>
                    <div
                      class="add-row-wrapper btn btn-success"
                      @click="setTokenSymbol(item.symbol)"
                      v-else
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-content-center align-items-center">
                      <img src="/images/Bitcoin-3.svg" alt="" class="img-fluid" />
                      <span class="textual">{{ item.name }}</span>
                    </div>
                  </td>
                  <td class="textual">${{ priceFormat(item.price) }}</td>
                  <td class="text-warning-50">
                    <span
                      class="ms-1"
                      :class="item.percent_change_7d > 0 ? '' : 'text-danger'"
                      ><i
                        class="fa"
                        :class="
                          item.percent_change_7d > 0
                            ? 'fa-angle-up text-success'
                            : 'fa-angle-down text-danger'
                        "
                        aria-hidden="true"
                      ></i>
                      {{ formatNumber(item.percent_change_7d) }}%</span
                    >
                  </td>
                  <td>${{ formatToMillionNumber(item.market_cap) }}</td>
                  <td class="h-volume">
                    <p class="mb-0">${{ formatToMillionNumber(item.volume_24h) }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <div class="col-md-4">
        <div class="nft-voucher-wrapper bg-dark-50">
          <h4 class="voucher-title text-left">NFT vouchers</h4>

          <ul class="nft-voucher__list" v-if="!isLoading && nfts.length > 0">
            <li
              v-for="(item, index) in nfts"
              :key="index"
              class="d-flex align-items-center mb-4"
              :class="{ selected: item.id.tokenId === selectedNFT }"
            >
              <div class="nft-voucher__pic">
                <img :src="item.metadata.image" alt="" />
              </div>
              <div class="nft-voucher__title ms-3">
                <h5>{{ item.metadata.name }}</h5>
                <p>{{ item.metadata.description }}</p>
              </div>
              <div class="nft-voucher-add">
                <a
                  href="#"
                  @click="
                    setTokenId(
                      item.id.tokenId,
                      item.metadata.name,
                      item.metadata.attributes
                    )
                  "
                >
                  <i
                    class="fa"
                    :class="item.id.tokenId === selectedNFT ? 'fa-minus' : 'fa-plus'"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </li>
          </ul>
          <div class="spinner-border" role="status" v-if="isLoading">
            <span class="sr-only" v-if="isLoading">Loading...</span>
          </div>
          <div v-if="!isLoading && nfts.length === 0">
            <span>No Voucher found</span>
          </div>
        </div>

        <div class="selected-token-wrapper bg-dark-50 mt-3">
          <h4 class="title pb-3">
            {{
              selectedCoin.length > 0
                ? "Tokens You Selected"
                : "No coin and nft selected yet"
            }}
          </h4>
          <div class="row" v-if="selectedCoin.length > 0">
            <div class="col-md-6">
              <ul class="selected-token__list p-0 mt-3">
                <li class="d-flex">
                  <div class="selected-token__name">
                    <div
                      v-for="(itemCoin, index) in selectedCoin"
                      :key="index"
                      class="d-flex align-items-center mb-1"
                    >
                      <div class="bg-pink active-token me-2"></div>
                      {{ itemCoin }}
                    </div>

                    <!-- <span class="text-uppercase">BYC nft token</span> -->
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-6" v-if="selectedNFT">
              <ul class="selected-token__list p-0 mt-3">
                <li class="d-flex">
                  <div class="bg-violet-blue active-token me-2"></div>
                  <div class="selected-token__name">
                    <h5>{{ selectedNFTName }}</h5>
                  </div>
                </li>
              </ul>
            </div>
            <section class="bottom-wrapper">
              <div class="row justify-content-end">
                <div class="col-md-12">
                  <div class="button-list">
                    <button class="bg-caribbean-green cos-btn" @click="createFund()">
                      Create Fund
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table > :not(caption) > * > * {
  background-color: inherit !important;
}

.nft-voucher__pic video {
  width: 100%;
  height: 100%;
}
.spinner-border {
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
  margin: 10px auto;
}
.bg-caribbean-green {
  background: #00dbaa;
}
.bg-red {
  background: #db0000;
}
.top-bar__filter-btn {
  position: relative;
}

.filter-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000; /* Ensure dropdown is above other elements */
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.table-wrapper .table tr {
  border-bottom: 1px solid #393c49;
}
.table th,
td {
  color: var(--whiteColor) !important;
  text-align: center;
  font-family: var(--BarlowFont);
}
.table th {
  font-size: 17px;
  font-weight: 600;
  padding: 20px 0;
}

.table td {
  padding: 30px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
}

.bottom-content__list {
  display: flex;
  align-items: self-start;
}
/* .clicked {
  background: #415f5c;
} */
.clicked h5 {
  color: aquamarine;
}
.clicked .textual {
  color: aquamarine;
}
.fa-angle-up,
.fa-angle-down {
  font-size: 18px;
}
</style>
