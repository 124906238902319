import axios from "axios";
import { defineStore } from "pinia";
import ApiService from "@/services/index";
export const useDefaultStore = defineStore("defaultStore", {
  state: () => {
    return { storeLoader: false, data: [], error: null };
  },
  getters: {
    fetchedCryptoData(state) {
      return state.data;
    },
    getCurrentPrice(state, sym) {
      const findSymbol = state.data.find((symbol) => symbol === sym);
      return findSymbol;
    },
  },
  actions: {
    setLoader(val) {
      this.loader = val;
    },
    async fetchedData() {
      try {
        this.storeLoader = true;
        this.error = null;
        const res = await axios.get(
          `${process.env.VUE_APP_BACKEND_API}/cryptoData`
        );
        this.data = res.data.data;
      } catch (error) {
        this.error = error;
        console.error("Error fetching data:", error);
      } finally {
        this.storeLoader = false;
      }
    },
    setData(payload) {
      this.data = payload;
    },
  },
});
export const useUserBalanceStore = defineStore("userBalance", {
  state: () => {
    return { balance: 0 };
  },
  getters: {
    getBalance(state) {
      return state.balance;
    },
  },
  actions: {
    async setBalance(val) {
      this.balance = val;
    },
    async fetchBalance(address) {
      return (this.balance = await ApiService.getBalance(address));
    },
  },
});
